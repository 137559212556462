.title {
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  h1 {
    $size: 2.5em;
    @include fluid-attr(font-size, $size * .7, $size);
  }
  h2 {
    $size: 1.25em;
    @include fluid-attr(font-size, $size * .8, $size);
  }
  h3 {
    $size: 1.375em;
    @include fluid-attr(font-size, $size * .8, $size);
  }
  @include spacers();
  @include text-colors();
  @include weights();

  &--center {
    text-align: center;
  }
}