*,
*:before,
*:after {
  box-sizing: border-box;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: $root-size;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
}

#root {
  height: auto;
  min-height: 100%;
}

* {
  &::selection {
    background-color: $color-primary;
    color: $color-white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  line-height: 1.2;
}

iframe,
video,
img {
  max-width: 100%;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

#root {
  display: none;
}
