.menu {
  $that: &;
  margin-left: -1em;
  margin-right: -1em;

  &--mobile {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &--desktop {
    width: 100%;
    height: auto;
    display: none;
    padding: 2em 0;
    max-width: 1500px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .item {
      color: $color-gray;
      $size: .9em;
      padding: 0 1em;
      letter-spacing: 1.5px;
      @include fluid-attr(font-size, $size * .8, $size);

      &--active {
        text-decoration: underline;
      }
    }

    #{$that}-left {
    }
    #{$that}-middle {
      max-width: 188px;

      img {
      }
    }
    #{$that}-right {
      //flex-grow: 1;
    }
  }

  &--mobile {
    .menu-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        max-width: 100px;
        height: auto;
        width: 100%;
        align-self: center;
      }
    }

    .navigation {
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: #fafafa;
      transition: all ease-in-out .5s;
      z-index: 100;
      top: 0;
      padding: 3em 2em;
      &__item {
        display: block;
        letter-spacing: 2px;
        color: $color-gray;
        font-weight: 400;
      }

      .times {
        font-size: 2.5em;
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 100;
        background-color: $color-black;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--visible {
        transform: translateX(0%);
      }

      &--hidden {
        transform: translateX(-100%);
      }
    }
  }
}