.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 2em 0;
  position: relative;

  &__logo {
    position: absolute;
    max-width: 300px;
    left: 0;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__nav {
    max-width: 66.1875em;
    margin: 0 auto;
  }

  a {
    font-size: 13px;
    font-weight: 600;
    color: rgba(112, 112, 112, .5);
    text-transform: uppercase;
    padding: 0 .7em;
    transition: all ease-in-out .15s;
    &:hover {
      color: rgba(112, 112, 112, .25);
    }
  }
}

.copyright {
  text-align: center;
  color: $color-gray;
}