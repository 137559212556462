.block {
  margin: 0;
  padding: 0;
  @include spacers();
  &--mobile-only {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &--flex {
    display: flex;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--align-center {
    align-items: center;
  }

  &--desktop-only {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}