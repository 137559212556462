.article {
  color: $color-blue-300;

  h1 {
    $size: 2.5em;
    $margin-y: 1.2em;
    @include fluid-attr(font-size, $size * .8, $size);
    @include fluid-attr(margin-bottom, $margin-y * .8, $margin-y);
  }

  p {
    $size: 1.1em;
    $margin-y: 1.2em;
    @include fluid-attr(font-size, $size * .8, $size);
    @include fluid-attr(margin-bottom, $margin-y * .8, $margin-y);
  }
}