$root-size: 16px;
$responsive-min: 16em;
$responsive-max: 75em;
$weights: 100, 200, 300, 400, 500, 600, 700;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
) !default;

$font-primary: futura-pt, sans-serif;

$color-primary: #e6007a;
$color-yellow: #F7D840;
$color-yellow-800: #F8DF61;
$color-blue-300: #707070;
$color-white: #ffffff;
$color-orange: #EF7F35;
$color-blue-100: #F1F9FF;
$color-blue-200: #BCE0FD;
$color-gray:#707070;
$color-gray-500: #7F8080;
$color-gray-100: #f1f1f1;
$color-gray-50: #fafafa;
$color-blue-900: #233d71;
$color-black: #000000;

$text-colors: (
        "color-primary": $color-primary,
        "color-blue-300": $color-blue-300,
        "color-gray": $color-gray,
        "color-blue-900": $color-blue-900,
);
$background-colors: (
        "color-blue-100": $color-blue-100,
);