.pager {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__pages {
    display: flex;
    justify-content: center;
    $padding-x: 1em;
    @include fluid-attr(padding-left, $padding-x * .8, $padding-x);
    @include fluid-attr(padding-right, $padding-x * .8, $padding-x);
  }
  
  &__control {
    img {
      cursor: pointer;
    }
  }

  &__link {
    $padding: 1em;
    @include fluid-attr(padding, $padding * .8, $padding);
    &--default {
      color: $color-blue-900;
      cursor: pointer;
    }
    &--active {
      font-weight: 600;
      color: $color-primary;
    }
  }
}