.cookie-banner,
.cookie-banner-detail {
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  @include padding(20px 30px);
  background-color: $color-black;
  z-index: 10000000;
  color: $color-white;

  &__content {
    margin-bottom: 20px;
  }
}

.cookie-banner {

}

.cookie-banner-detail {

  &-link {
    display: inline-block;
    color: $color-white;
    cursor: unset;
    @include margin-right(10px);
  }

  form {
    span {
      color: $color-orange;
      cursor: pointer;
    }
    button {
      margin-left: auto;
    }
  }
}
