.styled-link {
  margin: 0;
  @include padding(10px 20px);
  white-space: nowrap;
  display: inline-block;
  @include font-size(16px);
  color: $color-black;
  cursor: pointer;

  &.unstyled {
    font-size: unset;
    padding: 0;
  }

  &.link--default {
    border-radius: 5px;
    background-color: $color-yellow;
    transition: all ease-in-out .5s;

    &:hover {
      background-color: $color-yellow-800;
      transition: all ease-in-out .5s;
      box-shadow: 0 5px 0 $color-black;
    }
  }

  &.link--no-background {
    background-color: transparent;
    color: $color-white;
  }

  &.link--rounded {
    border-radius: 25px;
    background-color: $color-gray-500;
    @include padding(10px 30px);

    &:hover {
      background-color: $color-black;
      box-shadow: 0 5px 0 $color-black;
      transition: all ease-in-out .5s;
    }
  }

  &.link--rounded-gray {
    background-color: $color-gray-500;
    @include padding(10px 50px);
    border-radius: 25px;
    color: $color-white;
  }

  &.link--orange-pill {
    background-color: $color-orange;
    @include padding(10px 50px);
    color: $color-white;
    border-radius: 25px;
  }
}
