.content {
  @include spacers();
  @include text-colors();
  font-size: 1.2em;
  &--center {
    text-align: center;
  }

  &--flex {
    display: flex;
    align-items: center;
  }
}