.header {
  margin: 0;
  padding: 0;
  $height: 25em;
  @include fluid-attr(height, $height * .7, $height);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  $that: &;
  &--with-background {
    &::before {
      position: absolute;
      background-color: rgba($color-gray-100, .6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      content: "";
      transition: all ease-in-out .5s;
    }
  }

  &--default {
    background-color: $color-blue-900;
  }

  &--article {
    $height: 22.5em;
    @include fluid-attr(height, $height * .7, $height);
  }

  &__content {
    max-width: 500px;
    padding: 0 1em;
    z-index: 10;
  }
}