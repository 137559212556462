.button {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;

  &--default {
    border: 2px solid $color-blue-300;
    color: $color-blue-300;
    border-radius: .5em;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    img {
      margin-right: .8em;
      max-width: 16px;
    }
    $padding-x: 1em;
    $padding-y: 1em;
    @include fluid-attr(padding-left, $padding-x * .8, $padding-x);
    @include fluid-attr(padding-right, $padding-x * .8, $padding-x);
    @include fluid-attr(padding-top, $padding-y * .8, $padding-y);
    @include fluid-attr(padding-bottom, $padding-y * .8, $padding-y);
  }

  &--cookie-banner {
    border-radius: 5px;
    @include padding(10px 20px);
    color: $color-black;
    background-color: $color-yellow;
    transition: all ease-in-out .5s;
  }
}