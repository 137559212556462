.select {
  margin: 0;
  $padding-y: 1em;
  $padding-x: 1em;
  @include fluid-attr(padding-left, $padding-x * .8, $padding-x);
  @include fluid-attr(padding-right, $padding-x * .8, $padding-x);
  @include fluid-attr(padding-top, $padding-y * .8, $padding-y);
  @include fluid-attr(padding-bottom, $padding-y * .8, $padding-y);
  border: 2px solid $color-blue-900;
  background-color: $color-white;
  border-radius: .5em;
  color: $color-blue-300;
  max-width: 150px;
  width: 100%;
}