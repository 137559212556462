@use "sass:math";

@mixin background-opacity($color, $opacity: 0.3) {
  background: linear-gradient(45deg, rgba($color, $opacity), rgba($color-white, $opacity));
}
@mixin weights() {
  @each $weight in $weights {
    &--weight-#{$weight} {
      font-weight: $weight;
    }
  }
}

@mixin fluid-attr(
  $attr,
  $min-attr: 12px,
  $max-attr: 21px,
  $lower-range: $responsive-min,
  $upper-range: $responsive-max
) {
  #{$attr}: calc(
          #{$min-attr} + #{(
        math.div($max-attr, $max-attr * 0 + 1) - math.div($min-attr, $min-attr * 0 + 1)
      )} * ((100vw - #{$lower-range}) / #{(
            math.div($upper-range, $upper-range * 0 + 1) -
              math.div($lower-range, $lower-range * 0 + 1)
          )})
  );
  @media only screen and (max-width: $lower-range) {
    #{$attr}: $min-attr;
  }
  @media only screen and (min-width: $upper-range) {
    #{$attr}: $max-attr;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin scroll($scrollbars-visible: true) {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @if ($scrollbars-visible == false) {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@mixin child-flex-alignment {
  @each $name, $size in $grid-breakpoints {
    &--#{$name}\-justify-center {
      @media only screen and (min-width: $size) {
        justify-content: center;
      }
    }
    &--#{$name}\-justify-end {
      @media only screen and (min-width: $size) {
        justify-content: flex-end;
      }
    }
    &--#{$name}\-align-start {
      @media only screen and (min-width: $size) {
        align-items: flex-start;
      }
    }
    &--#{$name}\-align-center {
      @media only screen and (min-width: $size) {
        align-items: center;
      }
    }
  }
  &--flex-row {
    flex-direction: row;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--align-self-start {
    align-self: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--align-start {
    align-items: flex-start;
  }
  &--justify-center {
    justify-content: center;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--align-center {
    align-items: center;
  }
}

@mixin layout() {
  $sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
  $total-columns: 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
  &--1\/2 {
    width: 50%;
  }
  &--1\/4 {
    width: 25%;
  }
  &--1\/3 {
    width: calc(100% / 3);
  }
  &--1\/5 {
    width: calc(100% / 5);
  }

  &--5\/6 {
    width: calc((100% / 6) * 5);
  }

  &--1\/6 {
    width: calc(100% / 6);
  }

  &--2\/3 {
    width: calc((100% / 3) * 2);
  }
  &--2\/5 {
    width: calc((100% / 5) * 2);
  }

  &--3\/5 {
    width: calc((100% / 5) * 3);
  }

  &--3\/7 {
    width: calc((100% / 7) * 3);
  }
  &--2\/7 {
    width: calc((100% / 7) * 2);
  }

  &--3\/9 {
    width: calc((100% / 9) * 3);
  }

  &--3\/10 {
    width: calc((100% / 10) * 3);
  }
  &--4\/10 {
    width: calc((100% / 10) * 4);
  }

  &--order-1 {
    order: 1;
  }
  &--order-2 {
    order: 2;
  }
  &--order-3 {
    order: 3;
  }
  &--order-4 {
    order: 4;
  }
  &--order-5 {
    order: 5;
  }
  &--order-6 {
    order: 6;
  }

  @each $name, $size in $grid-breakpoints {
    @each $col in $sizes {
      @each $total-col in $total-columns {
        &--#{$name}\-#{$col}\/#{$total-col} {
          @media screen and (min-width: $size) {
            @if $col != 1 {
              width: calc((100% / #{$total-col}) * #{$col});
            } @else {
              width: calc((100% / #{$total-col}));
            }
          }
        }
      }
      &--#{$name}\-order-#{$col} {
        @media screen and (min-width: $size) {
          order: #{$col};
        }
      }
    }
    &--#{$name}\-align-start {
      @media screen and (min-width: $size) {
        align-items: flex-start;
      }
    }
  }
  @include child-flex-alignment();
}

@mixin spacers() {
  $space-ratio: .2;
  $spacers: 0em, .5em, 1em, 1.5em, 2em, 2.5em, 3em, 3.5em, 4em, 5em, 7em, 10em;

  @each $space in $spacers {
    $decimal: round($space) - $space;
    @if strip-unit($decimal) != 0 {
      @if (strip-unit(floor($space)) == 0) {
        &--left-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-left, $space * $space-ratio, $space);
        }
        &--right-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-right, $space * $space-ratio, $space);
        }
        &--horizontal-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-left, $space * $space-ratio, $space);
          @include fluid-attr(margin-right, $space * $space-ratio, $space);
        }
        //&--vertical-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
        //  @include fluid-attr(margin-top, $space * $space-ratio, $space);
        //  @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
        //}
        &--top-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-top, $space * $space-ratio, $space);
        }
        &--bottom-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
        }
      } @else {
        &--left-#{strip-unit(floor($space))}\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-left, $space * $space-ratio, $space);
        }
        &--right-\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-right, $space * $space-ratio, $space);
        }
        &--horizontal-#{strip-unit(floor($space))}\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-left, $space * $space-ratio, $space);
          @include fluid-attr(margin-right, $space * $space-ratio, $space);
        }
        //&--vertical-#{strip-unit(floor($space))}\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
        //  @include fluid-attr(margin-top, $space * $space-ratio, $space);
        //  @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
        //}
        &--top-#{strip-unit(floor($space))}\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-top, $space * $space-ratio, $space);
        }
        &--bottom-#{strip-unit(floor($space))}\.#{str-replace(#{strip-unit($decimal) + 'em'}, '0.', '')} {
          @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
        }
      }
    } @else {
      &--left-#{$space} {
        @include fluid-attr(margin-left, $space * $space-ratio, $space);
      }
      &--right-#{$space} {
        @include fluid-attr(margin-right, $space * $space-ratio, $space);
      }
      &--top-#{$space} {
        @include fluid-attr(margin-top, $space * $space-ratio, $space);
      }
      &--bottom-#{$space} {
        @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
      }
      &--vertical-#{$space} {
        @include fluid-attr(margin-top, $space * $space-ratio, $space);
        @include fluid-attr(margin-bottom, $space * $space-ratio, $space);
      }
      &--horizontal-#{$space} {
        @include fluid-attr(margin-left, $space * $space-ratio, $space);
        @include fluid-attr(margin-right, $space * $space-ratio, $space);
      }
    }
  }
}

@mixin text-colors() {
  @each $key, $color in $text-colors {
    &--#{$key} {
      color: $color !important;
    }
  }
}
@mixin background-colors() {
  @each $key, $color in $background-colors {
    &--#{$key} {
      background-color: $color;
    }
  }
}

@mixin scrollBar() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;// you need to tweak this to make it available..
    width: .6em;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-primary;
  }
}