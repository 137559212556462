.card {
  width: 100%;
  margin: 0;
  padding: 0;
  transition: all ease-in-out .5s;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  $that: &;
  &:hover {
    box-shadow: 0 0 15px 0 $color-gray;
    transform: scale(1.005);

    #{$that}__header--with-background {
      &:before {
        opacity: 0;
      }
    }
  }

  &__header {
    background-color: $color-blue-900;
    $height: 11.25em;
    @include fluid-attr(height, $height * .7, $height);
    position: relative;
    flex: 1;
    $that: &;

    &--with-background {
      background-size: cover;
      background-position: center;
      &:before {
        content: "";
        position: absolute;
        z-index: 100;
        background-color: rgba($color-blue-900, .5);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 1;
        transition: all ease-in-out .5s;
      }
    }

    &--default {

    }
  }

  &__body {
    height: 100%;
    $padding: 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-gray-100;
    @include fluid-attr(padding, $padding * .5, $padding);
  }
}